/*

Darkula color scheme from the JetBrains family of IDEs

*/


.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #2b2b2b;
  -webkit-text-size-adjust: none;
}

.hljs,
.hljs-tag,
.hljs-title,
.css .hljs-rule,
.css .hljs-value,
.aspectj .hljs-function,
.css .hljs-function .hljs-preprocessor,
.hljs-pragma {
  color: #bababa;
}

.hljs-strongemphasis,
.hljs-strong,
.hljs-emphasis {
  color: #a8a8a2;
}

.hljs-bullet,
.hljs-blockquote,
.hljs-horizontal_rule,
.hljs-number,
.hljs-regexp,
.alias .hljs-keyword,
.hljs-literal,
.hljs-hexcolor {
  color: #6896ba;
}

.hljs-tag .hljs-value,
.hljs-code,
.css .hljs-class,
.hljs-class .hljs-title:last-child {
  color: #a6e22e;
}

.hljs-link_url {
  font-size: 80%;
}

.hljs-emphasis,
.hljs-strongemphasis,
.hljs-class .hljs-title:last-child,
.hljs-typename {
  font-style: italic;
}

.hljs-keyword,
.ruby .hljs-class .hljs-keyword:first-child,
.ruby .hljs-function .hljs-keyword,
.hljs-function,
.hljs-change,
.hljs-winutils,
.hljs-flow,
.nginx .hljs-title,
.tex .hljs-special,
.hljs-header,
.hljs-attribute,
.hljs-symbol,
.hljs-symbol .hljs-string,
.hljs-tag .hljs-title,
.hljs-value,
.alias .hljs-keyword:first-child,
.css .hljs-tag,
.css .unit,
.css .hljs-important {
  color: #cb7832;
}

.hljs-function .hljs-keyword,
.hljs-class .hljs-keyword:first-child,
.hljs-aspect .hljs-keyword:first-child,
.hljs-constant,
.hljs-typename,
.css .hljs-attribute {
  color: #cb7832;
}

.hljs-variable,
.hljs-params,
.hljs-class .hljs-title,
.hljs-aspect .hljs-title {
  color: #b9b9b9;
}

.hljs-string,
.css .hljs-id,
.hljs-subst,
.hljs-type,
.ruby .hljs-class .hljs-parent,
.hljs-built_in,
.django .hljs-template_tag,
.django .hljs-variable,
.smalltalk .hljs-class,
.django .hljs-filter .hljs-argument,
.smalltalk .hljs-localvars,
.smalltalk .hljs-array,
.hljs-attr_selector,
.hljs-pseudo,
.hljs-addition,
.hljs-stream,
.hljs-envvar,
.apache .hljs-tag,
.apache .hljs-cbracket,
.tex .hljs-command,
.hljs-prompt,
.hljs-link_label,
.hljs-link_url,
.hljs-name {
  color: #e0c46c;
}

.hljs-comment,
.hljs-annotation,
.hljs-pi,
.hljs-doctype,
.hljs-deletion,
.hljs-shebang,
.apache .hljs-sqbracket,
.tex .hljs-formula {
  color: #7f7f7f;
}

.hljs-decorator {
  color: #bab429;
}

.coffeescript .javascript,
.javascript .xml,
.tex .hljs-formula,
.xml .javascript,
.xml .vbscript,
.xml .css,
.xml .hljs-cdata,
.xml .php,
.php .xml {
  opacity: 0.5;
}
