.smart_compare_state {
    font-size: 3.5em;
    position: absolute;
    right: 13px;
}

.smart_compare_addon {
    padding: 6px 10px;
    border-radius: 0;
    display: table-cell;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;

    border: 1px solid #ddd;
    border-right: 0;
    color: #555555;
    background-color: #fff;
}

.cursor__pointer {
    cursor: pointer;
}

.cursor__default {
    cursor: default;
}

.cursor__n-resize {
    cursor: n-resize;
}

.openclose-toggler__pointer {
    cursor: pointer;
}

.openclose-toggler__state {
    transition: transform 480ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

.openclose-toggler__state--open {
    transform: rotate(90deg);
}

.checkbox-selector__pointer {
    cursor: pointer;
}

.smart-timeline-content {
    padding-right: 10px;
}

.dropzone {
    border-style: dashed;
}

.timeline__message + .timeline__attachments {
    margin-top: 20px;
}

.attachment {
    margin-bottom: 5px;
}

.attachment__icon {
    padding-right: 10px;
}

.attachment__delete-link {
    margin-left: 20px;
}

.history-diff__table-row div {
    margin-bottom: 5px;
}

.account-info {

}

.account-info__icon,
.login-info a span.account-info__icon {
    font-size: 2em;
    margin-right: 7px;
}

.account-info__username {

}

.bg-color-danger {
    background-color: #f2dede !important;
}

.bg-color-warning {
    background-color: #fcf8e3 !important;
}

.bg-color-info {
    background-color: #d9edf7 !important;
}

.bg-color-success {
    background-color: #dff0d8 !important;
}

.bg-color-primary {
    color: #fff;
    background-color: #337ab7 !important;
}

.txt-color-black {
    color: #333333 !important;
}

textarea.autosize {
    box-sizing: border-box;
    max-height: 300px; /* optional, but recommended */
    min-height: 32px;
    overflow-x: hidden; /* for Firefox (issue #5) */
}
textarea.form-control.autosize {
    height: 32px;
}

.mb-1 {
    margin-bottom: 1em;
}
.mb-2 {
    margin-bottom: 2em;
}
.mb-3 {
    margin-bottom: 3em;
}
.mb-4 {
    margin-bottom: 4em;
}

.hidden-element {
    display: none;
}

.sticky-bottom-container {
    position: fixed;
    padding: 10px;
    background-color: #ddd;
    display: none;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 800;
}

.sticky-bottom-docked {
    display: block;
}

.postbox__iframe {
    width: 100%;
    min-height: 800px;
}
.postbox__text {
    min-height: 400px;
}
.postbox__button-link--small {
    padding: 0;
}

.sidebar-history {
    max-height: 100%;
    overflow: auto;
}
.sidebar-history__hr--small {
    margin-top: 5px;
    margin-bottom: 5px;
}

.mail--body__text {
    background-color: #fff;
    padding: 10px;
    font-family: monospace;
}
.mail--body__html {
    background-color: #fff;
    padding: 10px;
}
.mail--body__max-height {
    overflow: auto;
    max-height: 500px;
}

.timeline__mail ul {
    padding: 0 0 0 15px;
}
.timeline__mail li {
    padding: 0;
}

.filter-icon {
    display: none;
    width: 30px;
    height: 32px;
    position: relative;
    float: right;
    font-size: 111%;
    line-height: 32px;
    text-align: center;
    margin-left: -10px;
}
.filter-hide {
    display: none;
}

.panel__body--no-padding {
    padding: 0;
}
.panel__body--scrollable {
    overflow: auto;
}
.panel__body--height-150 {
    min-height: 150px;
}
.panel__body--scrollable-150 {
    max-height: 150px;
}
.panel__body--scrollable-200 {
    max-height: 200px;
}

.panel__table--no-margin {
    margin: 0;
}
.current-dataset__table .table:first-child tbody:first-child tr:first-child td,
.panel__table--no-margin.table:first-child tbody:first-child tr:first-child td {
    border-top: none;
}

.help-block--small {
    font-size: 92%;
    margin-bottom: 0;
}

.phpinfo__iframe {
    width: 100%;
    min-height: 800px;
    border: 0;
}

.input-loading {
    position: absolute;
    top: 7px;
    left: 22px;
}

.autocomplete {
    position: absolute;
    top: 3em;
    left: 2em;
    width: 90%;
    background-color: #fff;
    padding: 5px 30px 5px 5px;
    border: solid 1px #aaa;
    min-height: 40px;
    max-height: 400px;
    overflow-y: auto;
    z-index: 1990;
}
.autocomplete__close {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1999;
    cursor: pointer;
}
.autocomplete__loading {
    position: absolute;
    display: block;
    background-color: rgba(255, 255, 255, 0.6);
    margin: 0 auto;
    text-align: center;
    top: 0;
    left: 0;
    z-index: 1997;
    width: 100%;
    height: 100%;
}
.autocomplete__loading-icon {
    margin-top: 12px;
    z-index: 1998;
}
.autocomplete__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.autocomplete__list-item {
    cursor: pointer;
    padding: 5px;
}
.autocomplete__list-item:hover {
    background-color: #ecf3f8;
}

.no-margin {
    margin: 0;
}

.customer-select {
    position: relative;
    z-index: 2997;
}
.customer-select__wrapper {
    height: 100vh;
    width: 100vw;
    background-color: #000;
    opacity: .2;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2998;
}
.customer-select__container {
    height: 90vh;
    width: 90vw;
    background-image: url('/images/background.png');
    z-index: 2999;
    padding: 10px;
    overflow: auto;
    position: fixed;
    left: 5vw;
    top: 5vh;
}
.customer-select__container-form .form-group-hover {
    padding-top: 2px;
    padding-bottom: 2px;
}
.customer-select__container-form .form-group-hover:hover {
    background-color: #428BCA !important;
    color: #fff;
}
.customer-select__container-table {
    overflow-y: auto;
    max-height: 100%;
}
.ui-datepicker {
    z-index: 3999 !important;
}
.no-scroll {
    overflow: hidden;
}


#extr-page #header #logo,
#logo {
    margin-top: 8px;
}
#logo img,
#extr-page #header #logo img {
    height: 50px;
}

@media (max-width: 880px) and (min-width: 768px) {
    #extr-page #header {
        padding: 0 30px !important;
    }
}

#header {
    height: auto;
    min-height: 71px;
}
#header>div {
    height: 71px;
}
.btn-header>:first-child>a {
    margin-top: 23px;
}
#left-panel {
    padding-top: 69px;
}

