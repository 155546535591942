/*
 * SMARTADMIN VARIABLES
 * All variables related specifically to the theme starts from here:
 */
/* left side */
/* right side */
/* the color that flashes when you click on the first nav element */
/* the plus icon that appears on the parent nav when expanding and collapsing */
/* these values will only address the first parent in the entire nav */
/* the plus & minus icons */
/* margin top */
html,
body {
    background-image: url('/images/background.png');
}

#extr-page body {
    background-image: none;
}

#extr-page #main {
    padding-top: 0;
}

#extr-page #content {
    padding-top: 20px;
}

#extr-page #header {
    height: auto;
    min-height: 71px;
}

#logo img,
#extr-page #header #logo img {
    height: 25px;
    width: auto;
}

.glyphicon-2x {
    font-size: 2em;
}

.glyphicon-3x {
    font-size: 3em;
}

.glyphicon-4x {
    font-size: 4em;
}

.glyphicon-5x {
    font-size: 5em;
}

.glyphicon-6x {
    font-size: 6em;
}

.glyphicon-7x {
    font-size: 7em;
}

.glyphicon-8x {
    font-size: 8em;
}

.glyphicon-9x {
    font-size: 9em;
}

.glyphicon-10x {
    font-size: 10em;
}

.login-info a span {
    text-transform: none;
}

img.nostatus {
    border-left: none !important;
}

table.dataTable tr td {
    vertical-align: middle;
}

table.dataTable tr.odd {
    background-color: #F9F9F9;
}

table.dataTable tr.odd td.sorting_1 {
    background-color: #f3f3f3;
}

.jarviswidget table.dataTable tr.even {
    background-color: #FFFFFF;
}

table.dataTable tr.even td.sorting_1 {
    background-color: #f9f9f9;
}

table.dataTable tr:hover td {
    background-color: #ecf3f8 !important;
}

table.dataTable .dataTable-all-left,
table.dataTable thead .dataTable-head-left,
table.dataTable tfoot .dataTable-foot-left,
table.dataTable tbody .dataTable-body-left {
    text-align: left;
}

table.dataTable .dataTable-all-center,
table.dataTable thead .dataTable-head-center,
table.dataTable tfoot .dataTable-foot-center,
table.dataTable tbody .dataTable-body-center {
    text-align: center;
}

table.dataTable .dataTable-all-right,
table.dataTable thead .dataTable-head-right,
table.dataTable tfoot .dataTable-foot-right,
table.dataTable tbody .dataTable-body-right {
    text-align: right;
}

table.dataTable .dataTable-body-action {
    white-space: nowrap;
    width: 1% !important;
}

table.dataTable .dataTable-body-action .btn-group > .btn {
    float: none;
}

table.dataTable tfoot > tr > th {
    border-left: medium none !important;
    border-bottom: medium none !important;
}

table.dataTable tfoot .sum-money {
    white-space: nowrap;
}

.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 250px;
    height: 50px;
    margin-left: -125px;
    margin-top: -15px;
    padding: 14px 0 2px 0;
    border: 1px solid #ddd;
    text-align: center;
    color: #999;
    font-size: 14px;
    background-color: white;
}

.highlight {
    color: #428BCA;
    font-weight: bold;
    background: none;
}

.pace .pace-progress {
    background: #29d;
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    height: 2px;
    transition: width 1s;
}

.pace .pace-activity {
    right: 50%;
    top: 20px;
}

.pace-inactive {
    display: none;
}

.select2-container-multi .select2-choices li {
    float: none;
    width: 98%;
}

.select2-tags .select2-container-multi .select2-choices li {
    float: left;
    width: auto;
}

.select2-hidden-accessible {
    display: none !important;
    visibility: hidden !important;
}

label.mandatory,
.mandatory > label,
.mandatory > div > label {
    font-weight: bold;
}

.current-dataset {
    margin-bottom: 4em;
    max-width: 600px;
}

.current-dataset__table {
    padding: 0;
}

.project-select ul {
    margin: 0;
    padding: 0;
}

.project-select li {
    list-style-type: none;
    font-weight: normal;
}

.project-select__status {
    padding: 5px 10px;
    display: block;
    white-space: nowrap;
}

.project-select__mainproject-name {
    padding: 1px 10px 1px 20px;
    font-weight: bold;
    display: block;
    white-space: nowrap;
}

.project-select__project-name {
    padding: 1px 10px 1px 20px;
    display: block;
    white-space: nowrap;
}

.project-select__project-name:hover {
    background-color: #3276B1;
}

.project-select li.project-select__mainproject a {
    font-weight: bold;
}

.project-select li.project-select__project a {
    font-weight: normal;
    color: #333;
    padding: 3px 20px 3px 40px;
    display: block;
    white-space: nowrap;
}

.project-select li.project-select__project:hover,
.project-select li.project-select__active > a {
    background-color: #3276B1;
}

.project-select li.project-select__project:hover a,
.project-select li.project-select__active:hover > a,
.project-select li.project-select__active > a {
    color: #fff;
}

.project-select li.project-select__project:hover a {
    font-weight: normal;
}

.widget-select {
    margin: 12px -8px 12px 0;
}

.widget-select .dropdown-menu.pull-right {
    right: 15px;
}

table.table-white-bg {
    background-color: #fff;
}

.alert .table td {
    border-top: none;
}

.page-footer {
    height: auto;
    padding-bottom: 10px;
}

.no-padding .with-padding {
    padding: 13px 13px 0;
}

body .table .dyn-data__sum-cell {
    border-top: solid 1px #999999;
    font-weight: bold;
}

body .table .dyn-data__sum-cell--value {
    text-align: right;
}

.dyn-data__error {
    background-color: #F2DEDE;
}

.popover {
    max-width: 100%;
}

.pointer {
    cursor: pointer;
}

@media (min-width: 992px) {
    #logo {
        margin-left: 30px;
    }
}

#ribbon {
    background-color: #0096d8;
}

#ribbon .breadcrumb a,
#ribbon .breadcrumb {
    color: #f5f5f5 !important;
}

#header {
    background-color: #e4f2fa;
    background-image: none;
}

.page-footer {
    background: none;
    border-top: solid 1px #f4f4f4;
}

.well {
    box-shadow: none;
}

.form header,
legend,
.bootstrap-duallistbox-container label {
    border-bottom-style: dotted;
}

aside {
    background: #eee761;
}

.jumbotron {
    background: #e4f2fa;
}

.login-info {
    border-top: solid 20px #0096d8;
    border-bottom: 1px solid #e9e033;
}

.login-info > span {
    border-bottom: 1px solid #faf8d4;
}

.login-info a {
    color: #808080;
}

.login-info a:hover {
    color: #000000;
}

nav ul ul {
    background-color: #faf8d4;
}

nav ul li a,
nav ul ul ul li a {
    color: #808080;
}

nav ul .active {
    background-color: #006088;
}

nav ul li.open > a,
nav ul li.open > a b,
nav ul li a:hover {
    color: #000000 !important;
}

nav ul ul li > a:hover,
nav ul ul li > a:active,
nav ul li a:active {
    background-color: rgba(0, 96, 136, 0.75) !important;
    color: #ffffff !important;
}

#extr-page #header {
    background-color: #e4f2fa !important;
}

/*# sourceMappingURL=app.css.map */

.dropzone .dz-remove {
    color: inherit !important;
    border: inherit !important;
}

.monospace {
    font-family: Monospace;
}

.postponed td {
    opacity: .3;
}

.deleted td {
    background-color: rgb(238, 238, 238) !important;
}
.deleted td .btn {
    opacity: .6;
}

.mb1 {
    margin-bottom: 1em;
}
.mb2 {
    margin-bottom: 2em;
}
.mb3 {
    margin-bottom: 3em;
}